import React, { useState } from "react";
import { Card } from "melodies-source/Card";
import { H2 } from "melodies-source/Text";
import { Divider, useArtistContext } from "Components";
import {
  useCollection,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import {
  collection,
  getFirestore,
  orderBy,
  Query,
  query,
  where,
} from "firebase/firestore";
import { ReleaseDetail } from "./ReleaseDetail";
import { Spinner } from "melodies-source/Spinner";
import { TotalPreSaves } from "./TotalPreSaves";
import styled from "styled-components";
import { ServiceChart } from "./ServiceChart";
import { StatusChart } from "./StatusChart";
import { SourceChart } from "./SourceChart";
import { InsufficientData } from "./InsuffcientData";
import { RecentPreSaves } from "./RecentPreSaves";
import {
  ArtistRelease,
  ReleasePresave,
} from "@max/common/dist/artists/presave";

export const PreSaves: React.FC = () => {
  const { id: artistGroupId } = useArtistContext();
  const [selectedReleaseIndex, setSelectedReleaseIndex] = useState<number>(0);
  const [artistReleasesSnapshot, artistReleasesSnapshotLoading] = useCollection(
    query(
      collection(getFirestore(), "artist_releases"),
      where("artistGroupId", "==", artistGroupId),
      where("image", "!=", null),
      where("deletedAt", "==", null),
      orderBy("releaseDate", "desc"),
    ) as Query<ArtistRelease>,
  );

  const artistReleaseDoc = artistReleasesSnapshot?.docs[selectedReleaseIndex];
  const [preSaves, preSavesLoading] = useCollectionData(
    query(
      collection(getFirestore(), "release_presaves"),
      where("artistGroupId", "==", artistGroupId),
      where("releaseIds", "array-contains", artistReleaseDoc?.id || ""),
      where("timestamp", "!=", null),
    ) as Query<ReleasePresave>,
  );

  const handleBack = () =>
    setSelectedReleaseIndex((index) => Math.max(0, index - 1));

  const handleNext = () =>
    setSelectedReleaseIndex((index) =>
      Math.min(artistReleasesSnapshot?.docs.length - 1, index + 1),
    );

  if (
    artistReleasesSnapshotLoading ||
    artistReleasesSnapshot?.docs.length === 0
  ) {
    return null;
  }

  return (
    <Card isElevated>
      <Container>
        <H2>Pre-Saves</H2>
        <FirstRow>
          {artistReleaseDoc && (
            <ReleaseDetail
              release={artistReleaseDoc.data()}
              releaseIndex={selectedReleaseIndex}
              releasesLength={artistReleasesSnapshot.docs.length}
              onBack={handleBack}
              onNext={handleNext}
            />
          )}
          {preSavesLoading ? (
            <>
              <StyledSpinner />
              <StyledSpinner />
            </>
          ) : (
            <>
              <TotalPreSaves
                preSaves={preSaves}
                releaseId={artistReleaseDoc?.id}
                release={artistReleaseDoc?.data()}
              />
              <RecentPreSaves
                preSaves={preSaves}
                releaseId={artistReleaseDoc?.id}
              />
            </>
          )}
        </FirstRow>
        <StyledDivider />
        <SecondRow>
          {preSavesLoading ? (
            <Spinner />
          ) : preSaves.length === 0 ? (
            <InsufficientData
              message={`This section will populate as more fans pre-save ${
                artistReleaseDoc ? "this release" : "releases"
              }`}
            />
          ) : (
            <>
              <ServiceChart preSaves={preSaves} />
              <StatusChart
                preSaves={preSaves}
                releaseId={artistReleaseDoc?.id}
              />
              <SourceChart
                preSaves={preSaves}
                releaseId={artistReleaseDoc?.id}
              />
            </>
          )}
        </SecondRow>
      </Container>
    </Card>
  );
};

const Container = styled.div`
  text-align: center;
  ${H2} {
    margin-bottom: 20px;
  }
`;

const FirstRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    gap: 50px;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 40px 0;
`;

const SecondRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  min-height: 320px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    min-height: unset;
  }
`;

const StyledSpinner = styled(Spinner)`
  flex: 1;
  padding: 0 15px;
  min-height: 150px;
`;
