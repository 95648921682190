import React, { useEffect, useState } from "react";
import { PieChart } from "../Components/PieChart";
import { KeyedValue } from "Components";
import {
  PresaveReleaseStatus,
  ReleasePresave,
} from "@max/common/dist/artists/presave";

const STATUSES = ["delivered", "pending", "failed"] as const;
type Status = (typeof STATUSES)[number];

interface Props {
  preSaves: ReleasePresave[];
  releaseId?: string;
}

export const StatusChart: React.FC<Props> = ({ releaseId, preSaves }) => {
  const [data, setData] = useState<KeyedValue[]>([]);

  const getData = () => {
    const statusLabels: Record<Status, string> = {
      delivered: "Delivered",
      pending: "Pending",
      failed: "Failed",
    };

    const statusMap: Record<Status, PresaveReleaseStatus[]> = {
      delivered: ["success"],
      pending: ["scheduled", "pending"],
      failed: ["fail"],
    };

    const preSavesByStatus: Record<Status, number> = {
      delivered: 0,
      pending: 0,
      failed: 0,
    };

    for (const preSave of preSaves) {
      const status = preSave.completedReleases[releaseId].status;
      let statusKey = Object.entries(statusMap).find(([_, values]) =>
        values.includes(status),
      )?.[0];
      if (statusKey) {
        preSavesByStatus[statusKey] += 1;
      }
    }

    return Object.entries(preSavesByStatus).map(([status, count]) => ({
      id: status,
      label: statusLabels[status],
      count: count,
    }));
  };

  useEffect(() => {
    const data = getData();
    setData(data);
  }, [preSaves.length]);

  return <PieChart title="Pre-Save Status" data={data} />;
};
