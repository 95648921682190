import styled from "styled-components";
import { useArtistContext } from "Components";
import { Avatar as AvatarBase } from "melodies-source/Avatar";
import { Body2, H4 } from "melodies-source/Text";
import { Button } from "melodies-source/Button";
import { SvgDownload } from "melodies-source/Svgs/Download";
import { SvgCopy } from "melodies-source/Svgs/Copy";
import { ReactComponent as ChevronLeft } from "assets/svg/chevron-left.svg";
import { ReactComponent as ChevronRight } from "assets/svg/chevron-right.svg";
import QRCodeStyling, { Options, FileExtension } from "qr-code-styling";
import { toast } from "react-hot-toast";
import { useDialog } from "Components/DialogProvider";
import { Modal } from "melodies-source/Modal";
import { Link } from "Components/Link";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { useEffect, useRef, useState } from "react";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import {
  collection,
  doc,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { DateTime } from "luxon";
import { CircularLoader } from "Components";

const initialQrState = {
  width: 1380,
  height: 1380,
  dotsOptions: {
    type: "dots",
  },
  cornersSquareOptions: {
    type: "square",
  },
  cornersDotOptions: {
    type: "dot",
  },
} as Partial<Options>;

export const QrCodeModal = () => {
  const { assets, name, id } = useArtistContext();
  const { setDialog } = useDialog();
  const { customApp, language } = useCustomAppContext();
  const [idxForward, setIdxForward] = useState(1);
  const [idxBack, setIdxBack] = useState(5);
  const [logoUrl, setLogoUrl] = useState("");
  const ref = useRef(null);
  const qrLink = useRef<string>();
  const qrCode = useRef<QRCodeStyling>();
  const [now] = useState<Date>(DateTime.utc().toJSDate());

  const [event] = useCollection(
    query(
      collection(getFirestore(), "set_fresh_events"),
      where("groupId", "==", id),
      where("startsAt", ">", now),
      orderBy("startsAt", "desc"),
      limit(1),
    ),
  );
  const [latestEventId] = event?.docs?.map((docs) => docs?.id) || [];

  const [eventConfig] = useDocumentData(
    !latestEventId
      ? null
      : doc(getFirestore(), `/set_fresh_events/${latestEventId}/versions/en`),
  );

  const eventColor = eventConfig?.colors?.dark || "#000000";

  useEffect(() => {
    if (id)
      qrLink.current = new URL(
        `/group/${id}`,
        customApp ? customApp.fanAppUrl : process.env.REACT_APP_SL_URL,
      ).toString();
    qrCode.current = new QRCodeStyling(initialQrState);
    if (qrLink.current)
      qrCode.current.update({
        data: qrLink.current,
      });
    if (logoUrl && eventColor) {
      let update = {
        ...initialQrState,
        image: logoUrl,
        dotsOptions: {
          color: eventColor,
          type: "dots",
        },
        cornersSquareOptions: {
          type: "square",
          color: eventColor,
        },
        cornersDotOptions: {
          color: eventColor,
          type: "dot",
        },
      } as Partial<Options>;
      qrCode.current.update({ ...initialQrState, ...update });
      ref.current.innerHTML = "";
      qrCode.current.append(ref.current);
    }
  }, [logoUrl, eventColor, id, customApp]);

  useEffect(() => {
    const canvas: HTMLCanvasElement = document.querySelector("#canvasConvert");
    const ctx = canvas?.getContext("2d");
    if (ctx) {
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      const image = new Image();
      image.src = assets?.icon?.path;
      image.crossOrigin = "anonymous";
      image.onload = async function () {
        ctx.beginPath();
        ctx.arc(135, 135, 105, 0, Math.PI * 2);
        ctx.closePath();
        ctx.clip();
        ctx.drawImage(image, 10, 10, canvas.width - 20, canvas.height - 20);
        const imageUrl: string = await new Promise((resolve) => {
          canvas.toBlob(function (blob) {
            resolve(URL.createObjectURL(blob));
          });
        });
        setLogoUrl(imageUrl);
      };
    }
  }, [assets?.icon?.path]);

  const updateStyleForward = (idx: number, increment?: number) => {
    let i = idx;
    if (!increment) {
      setIdxBack((prev) => {
        if (prev < 1) {
          prev = 5;
        } else {
          prev--;
        }
        return prev;
      });
      setIdxForward(idx + 1);
    } else {
      setIdxForward((prev) => {
        if (prev > 4) {
          prev = 0;
        } else {
          prev++;
        }
        return prev;
      });
      setIdxBack(idx - 1);
    }
    const options = [
      logoUrl,
      `${process.env.PUBLIC_URL}/SET_sq_logo.png`,
      "",
      logoUrl,
      `${process.env.PUBLIC_URL}/SET_sq_logo.png`,
      "",
    ];
    const colors = [
      eventColor,
      eventColor,
      eventColor,
      "#000000",
      "#000000",
      "#000000",
    ];
    let update = {
      image: options[i],
      dotsOptions: {
        color: colors[i],
        type: "dots",
      },
      cornersSquareOptions: {
        type: "square",
        color: colors[i],
      },
      cornersDotOptions: {
        color: colors[i],
        type: "dot",
      },
    } as Partial<Options>;
    qrCode.current?.update(update);
    ref.current.innerHTML = "";
    qrCode.current?.append(ref.current);
  };

  const onDownloadClick = (extension: FileExtension) => {
    qrCode.current?.download({
      extension,
      name: name + " - QR Code",
    });
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(qrLink?.current?.toString());
    toast.success("Copied to clipboard.");
  };

  return (
    <div>
      <Modal
        isOpen
        onClose={() => setDialog({})}
        header={`${language.event.owner.singular} QR Code`}
      >
        <Wrapper>
          <Container>
            <Avatar imgUrl={assets?.icon?.path} />
            <H4>{name}</H4>{" "}
            <QRCodeWrapper>
              <ChevronLeft onClick={() => updateStyleForward(idxBack, 0)} />
              <QRCode>
                {!logoUrl && !eventColor && <CircularLoader />}
                <div
                  ref={ref}
                  id="qr-code"
                  style={{ visibility: logoUrl ? "visible" : "hidden" }}
                />
              </QRCode>
              <ChevronRight onClick={() => updateStyleForward(idxForward, 1)} />
            </QRCodeWrapper>
          </Container>
          <Body2>
            This QR code will direct{" "}
            {language.event.audience.plural.toLowerCase()} to your current{" "}
            {language.event.type.singular.toLowerCase()} or your next upcoming{" "}
            {language.event.type.singular.toLowerCase()}, regardless of their
            location.
          </Body2>
          <Actions>
            <ButtonWrapper>
              <Button
                style={{ width: "100%" }}
                onClick={() => onDownloadClick("png")}
                variant="secondary"
                leftIcon={<SvgDownload />}
              >
                PNG
              </Button>
              <Body2>Best for screens</Body2>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                style={{ width: "100%" }}
                onClick={() => onDownloadClick("svg")}
                leftIcon={<SvgDownload />}
              >
                SVG
              </Button>
              <Body2>Best for print</Body2>
            </ButtonWrapper>
          </Actions>
          <ButtonWrapper>
            <Link onClick={handleCopy}>
              Copy {language.event.owner.singular} Link{" "}
              <SvgCopy style={{ marginLeft: 12 }} />
            </Link>
            <Body2>This is the same link as the QR code.</Body2>
          </ButtonWrapper>
        </Wrapper>
      </Modal>
      <canvas
        id="canvasConvert"
        style={{ display: "none" }}
        width="270"
        height="270"
      />
    </div>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${Body2} {
    text-align: center;
    margin-top: 4px;
  }
  & + & {
    margin-top: 12px;
  }
  ${({ theme }) => theme.mediaQueries.desktop} {
    & + & {
      margin-top: 0;
      margin-left: 16px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 68px;
  ${Link} {
    display: flex;
    align-items: center;
  }
  ${Body2} {
    text-align: center;
    margin-top: 20px;
    width: 100%;
  }
`;

const Container = styled.div`
  background-color: var(--content-background-color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 24px;

  ${H4} {
    text-align: center;
    margin: 8px 0 12px;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 24px 0;
  }
`;

const QRCodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin: 20px auto auto;
  width: 100%;
  height: 250px;

  svg {
    cursor: pointer;
    margin-bottom: -10px;
    height: 50px;
    width: 50px;
    color: var(--main-color);
  }
`;
const QRCode = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 230px;
  & > div#qr-code {
    position: absolute;
    scale: 0.17;
  }
  @media (max-width: 480px) {
    & > div#qr-code {
      scale: 0.14;
    }
  }
`;
const Avatar = styled(AvatarBase)`
  width: 94px;
  height: 94px;
  margin-top: -71px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0 16px;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.desktop} {
    flex-direction: row;
  }
`;
