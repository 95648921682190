import React from "react";
import { DateTime } from "luxon";
import styled from "styled-components";
import { ReactComponent as ChevronLeft } from "assets/svg/chevron-left.svg";
import { ReactComponent as ChevronRight } from "assets/svg/chevron-right.svg";
import { ArtistRelease } from "@max/common/dist/artists/presave";

interface Props {
  release: ArtistRelease;
  releaseIndex: number;
  releasesLength: number;
  onNext: () => void;
  onBack: () => void;
}

export const ReleaseDetail: React.FC<Props> = ({
  release,
  releaseIndex,
  releasesLength,
  onBack,
  onNext,
}) => {
  const dateTime = DateTime.fromJSDate(release.releaseDate.toDate());

  return (
    <Container>
      <ImageContainer>
        <ChevronLeft
          onClick={onBack}
          style={{ visibility: releaseIndex > 0 ? "visible" : "hidden" }}
        />
        <Image src={release.image} alt={release.title} />
        <ChevronRight
          onClick={onNext}
          style={{
            visibility:
              releaseIndex < releasesLength - 1 ? "visible" : "hidden",
          }}
        />
      </ImageContainer>
      <Title>{release.title}</Title>
      <Date>
        Released{" "}
        {`${dateTime.toFormat("MM/dd/yy '@' h:mma")}  ${
          dateTime.offsetNameShort
        }`}
      </Date>
      <Code>UPC# {release.upc}</Code>
      {release.isrc && <Code>ISRC# {release.isrc}</Code>}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  color: var(--text-color);
  min-width: 250px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  svg {
    width: 40px;
    height: 40px;
    color: #8017f6;
    cursor: pointer;
    flex-shrink: 0;
  }
`;

const Image = styled.img`
  width: 100%;
  aspect-ratio: 1;
  user-select: none;
  pointer-events: none;
  max-width: 160px;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin: 5px 0;
`;

const Date = styled.span`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 2px;
`;

const Code = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
`;
