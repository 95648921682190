import React from "react";
import { Modal as MelodiesModal } from "melodies-source/Modal";
import styled from "styled-components";
import { BtnProps, Button, ButtonType } from "melodies-source/Button";

interface Action extends Omit<BtnProps, "text" | "children"> {
  text: string;
}

export interface ModalProps extends React.ComponentProps<typeof MelodiesModal> {
  actions?: Record<"secondary" | "main", Action>;
  isForm?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  actions,
  isForm,
  ...props
}) => {
  const { text: secondaryButtonText, ...secondaryButtonProps } =
    actions?.secondary || {};
  const {
    text: mainButtonText,
    onClick: mainButtonOnClick,
    ...mainButtonProps
  } = actions?.main || {};

  const content = (
    <>
      {children}
      {actions && (
        <Actions>
          <Button
            {...secondaryButtonProps}
            variant={actions.secondary.variant || "tertiary"}
          >
            {secondaryButtonText}
          </Button>
          <Button
            {...mainButtonProps}
            variant={actions.main.variant || "primary"}
            {...(isForm ? { type: "submit" } : { onClick: mainButtonOnClick })}
          >
            {mainButtonText}
          </Button>
        </Actions>
      )}
    </>
  );
  return (
    <MelodiesModal {...props}>
      {isForm ? <Form onSubmit={mainButtonOnClick}>{content}</Form> : content}
    </MelodiesModal>
  );
};

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 30px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column-reverse;

    button {
      width: 100%;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
