import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DateTime, Interval } from "luxon";
import { KeyedValue, MixedChart } from "Components";
import { H3 } from "melodies-source/Text";
import { ReleasePresave } from "@max/common/dist/artists/presave";

interface Props {
  preSaves: ReleasePresave[];
  releaseId?: string;
}

const DATE_FORMAT = "MM/dd";

export const RecentPreSaves: React.FC<Props> = ({ releaseId, preSaves }) => {
  const [data, setData] = useState<KeyedValue[]>([]);

  const getData = () => {
    const preSavesByDate = {};
    for (const preSave of preSaves) {
      const { createdAt } = preSave.completedReleases[releaseId];
      if (createdAt) {
        const date = DateTime.fromMillis(createdAt.toMillis());
        const dateLabel = date.toFormat(DATE_FORMAT);
        preSavesByDate[dateLabel] = (preSavesByDate[dateLabel] || 0) + 1;
      }
    }

    const start = DateTime.fromMillis(Date.now());
    return Interval.fromDateTimes(
      start.startOf("day").minus({ days: 6 }),
      start,
    )
      .splitBy({ days: 1 })
      .map((d) => {
        const dateLabel = d.start.toFormat(DATE_FORMAT);
        return {
          id: dateLabel,
          label: dateLabel,
          count: preSavesByDate[dateLabel] || 0,
        };
      });
  };

  useEffect(() => {
    setData(getData());
  }, [preSaves.length]);

  return (
    <Container>
      <H3>Recent Pre-Saves</H3>
      <StyledMixedChart
        indexBy="label"
        data={data}
        defs={[
          {
            chartType: "bar",
            dataKey: "count",
            fill: "#8017F6",
            showLabel: true,
            formatLabel: (label: any) =>
              typeof label === "number" && label > 0 ? label : undefined,
          },
        ]}
        hideLegend
        hideYAxis
        hideTooltip
        tickFont={{
          fontSize: "10px",
          fontWeight: 700,
          color: "#999",
        }}
        tickMarginXAxis={5}
        height={160}
      />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  min-width: 300px;
`;

const StyledMixedChart = styled(MixedChart)`
  min-height: 0;
  width: 100%;
  height: 100% !important;
  margin: 20px 0 0px;

  .recharts-text.recharts-label {
    fill: var(--text-color);
  }

  .recharts-wrapper {
    max-height: 100% !important;
  }
`;
