import styled, { css } from "styled-components";
import { ReactComponent as IconGlobe } from "assets/svg/globe-gradient.svg";
import { ReactComponent as IconPointer } from "assets/svg/pointer-gradient.svg";
import { ReactComponent as IconUser } from "assets/svg/user-gradient.svg";
import { FlexColumn, FlexRow } from "Components/Flex";
import { Body1, H1, H2 } from "melodies-source/Text";
import { Button } from "melodies-source/Button";
import { SvgCopy } from "melodies-source/Svgs/Copy";
import { useMemo, useState } from "react";
import {
  useCollectionData,
  useCollectionDataOnce,
} from "react-firebase-hooks/firestore";
import { firestore } from "firebase-internal";
import { DateTime, DurationLike } from "luxon";
import { HourlyAnalytics as HourlyAnalyticsBase } from "@max/common/src/setpage/analytics";
import { processMetrics } from "./getMetrics";
import {
  collection,
  query,
  where,
  documentId,
  Query,
  FirestoreDataConverter,
  WithFieldValue,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "@firebase/firestore";
import {
  ClicksByDSP,
  ClicksBySection,
  FlatCard,
  SignUps,
  ChartTabs,
  TopLinks,
  TopReleases,
  TopSources,
} from "./Components";
import { Select } from "Components/Select";
import { BlankState } from "./Components/BlankState";
import { Spinner } from "melodies-source/Spinner";
import { useHistory, useParams } from "react-router-dom";
import { useArtistGroupSubdomain } from "hooks/useArtistGroupSubdomains";
import { addToast } from "melodies-source/Toast";
import { PreSaves } from "./PreSaves";

export type HourlyAnalyticsWithId = HourlyAnalyticsBase & {
  id: string;
  date?: DateTime;
};

export type DateRange = {
  duration: DurationLike;
  start?: DateTime;
};

type DateRangeOption = DateRange & {
  label: string;
};

const dateRangeOptions: DateRangeOption[] = [
  {
    label: "Past 7 Days",
    duration: { days: 6 },
  },
  {
    label: "Past 30 Days",
    duration: { days: 29 },
  },
  {
    label: "Past 60 Days",
    duration: { days: 59 },
  },
  {
    label: "Past 3 months",
    duration: { months: 3 },
  },
  {
    label: "Past 6 months",
    duration: { months: 6 },
  },
];

const postConverter: FirestoreDataConverter<HourlyAnalyticsBase> = {
  toFirestore(hour: WithFieldValue<HourlyAnalyticsBase>): DocumentData {
    return hour;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): HourlyAnalyticsWithId {
    const data = snapshot.data(options) as HourlyAnalyticsBase;
    return {
      ...data,
      id: `${Math.floor(parseInt(snapshot.id) / 24)}`,
    };
  },
};

export const SetBioReporting = () => {
  const [dateRange, setDateRange] = useState<DateRangeOption>(
    dateRangeOptions[1],
  );
  const params = useParams<{ artistId: string }>();
  const [subdomain, subLoading] = useArtistGroupSubdomain(params.artistId);
  const nav = useHistory();

  const [savedModules, savedModulesLoading] = useCollectionDataOnce(
    collection(firestore, "set_page", params.artistId, "modules"),
  );

  const handleCopyLink = () => {
    if (!subdomain?.id) return;
    const hostname = new URL(
      process.env.REACT_APP_VERIFY_REDIRECT,
    )?.hostname?.split(".")?.[0];
    navigator.clipboard.writeText(`https://${subdomain.id}.${hostname}.bio`);
    addToast("Link copied to clipboard", "success");
  };

  const duration = useMemo(() => {
    const toHours = (dates: DateTime[]) =>
      dates.map((date) => Math.floor(date.toMillis() / (60 * 60 * 1000)));
    const startRange = (dateRange.start || DateTime.now()).endOf("day");
    const start = startRange.startOf("day").minus(dateRange.duration);
    const end = startRange;
    return toHours([start, end]);
  }, [dateRange]);

  const [docs, docsLoading] = useCollectionData(
    duration &&
      (query(
        collection(
          firestore,
          "set_page",
          params.artistId,
          "analytics_hourly",
        ).withConverter(postConverter),
        where(documentId(), ">", duration[0].toString()),
        where(documentId(), "<", duration[1].toString()),
      ) as Query<HourlyAnalyticsWithId>),
  );

  const metrics = processMetrics(docs);
  const loading = docsLoading || !metrics;

  const setBioExists = !savedModulesLoading && !!savedModules?.length;
  const subdomainExists = !subLoading && subdomain?.id;
  const isNoDataView = !subdomainExists || !setBioExists;
  const signupModuleExists = savedModules?.find(
    (m) => m.type === "signup_form",
  );

  const selOptions = dateRangeOptions.map(({ label }) => ({
    label,
    value: label,
  }));

  if (loading && !docs?.length) {
    return <Spinner style={{ flexGrow: 1 }} />;
  }

  return (
    <Wrapper>
      <Header>
        <H2>Page Performance</H2>
        {setBioExists && (
          <RoundButton variant="round" onClick={handleCopyLink}>
            {subLoading ? <Spinner /> : subdomain?.id}.set.bio
            <SvgCopy />
          </RoundButton>
        )}
        <Button onClick={() => nav.push("../set-bio")}>
          {setBioExists ? "Edit Page" : "Get Started"}
        </Button>
        {!isNoDataView && (
          <SelectContainer>
            <StyledSelect
              value={dateRange.label}
              onChange={(v) =>
                setDateRange(dateRangeOptions.find(({ label }) => label === v))
              }
              options={selOptions}
            />
          </SelectContainer>
        )}
      </Header>

      {isNoDataView ? (
        <BlankState />
      ) : (
        <>
          <Shelf>
            <FlatCard>
              <IconGlobe />
              <FlexColumn>
                <H1>{metrics.totalVisits.toLocaleString()}</H1>
                <Body1>Total Visits</Body1>
              </FlexColumn>
            </FlatCard>
            <FlatCard>
              <IconUser />
              <FlexColumn>
                <H1>{metrics.uniqueVisits.toLocaleString()}</H1>
                <Body1>Unique Visits</Body1>
              </FlexColumn>
            </FlatCard>
            <FlatCard>
              <IconPointer />
              <FlexColumn>
                <H1>{metrics.totalClicks.toLocaleString()}</H1>
                <Body1>Total Clicks</Body1>
              </FlexColumn>
            </FlatCard>
          </Shelf>
          <StyledChartTabs dateRange={dateRange} hours={docs} />
          <ChartWrapper>
            {!(!metrics.topLinks.length && !metrics.clicksBySection.length) && (
              <>
                <TopLinks metrics={metrics.topLinks} />
                <ClicksBySection metrics={metrics.clicksBySection} />
              </>
            )}
            {!(!metrics.topReleases.length && !metrics.clicksByDsp.length) && (
              <>
                <TopReleases metrics={metrics.topReleases} />
                <ClicksByDSP metrics={metrics.clicksByDsp} />
              </>
            )}
            <TopSources sources={metrics.topSources} />
            <SignUps
              total={
                !signupModuleExists && !metrics.signups
                  ? undefined
                  : metrics.signups
              }
            />
          </ChartWrapper>
          <PreSaves />
        </>
      )}
    </Wrapper>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
  ${({ theme }) => theme.mediaQueries.desktop} {
    max-width: 200px;
  }
`;

const SelectContainer = styled(FlexRow)`
  ${({ theme }) => theme.mediaQueries.desktop} {
    justify-content: flex-end;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 24px;
    margin-bottom: -12px;
  }
`;

const StyledChartTabs = styled(ChartTabs)`
  ${({ theme }) => theme.mediaQueries.desktop} {
    grid-column: 1 / span 2;
  }
`;

const Header = styled.div`
  display: grid;
  gap: 24px;

  ${({ theme }) => theme.mediaQueries.desktop} {
    margin-bottom: 12px;
    grid-template-columns: auto auto auto 1fr;
    align-items: center;
    flex-direction: row;
    grid-column: 1 / span 2;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    grid-template-columns: 1fr;
    gap: 12px;
    ${H2} {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
    }
    button {
      width: 100%;
    }
  }
`;

const RoundButton = styled(Button)`
  border: 1px solid var(--border-color);
  color: var(--main-color);
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px 10px 18px;
  height: auto;
  gap: 12px;

  ${({ theme }) =>
    theme.custom &&
    css`
      border-color: var(--main-color);
    `}
`;

const Wrapper = styled(FlexColumn)`
  gap: 32px;
  width: 100%;
  flex-grow: 1;

  ${({ theme }) => theme.mediaQueries.desktop} {
    gap: 24px;
    padding: 20px 24px;
  }
`;

const ChartWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 32px;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.desktop} {
    grid-template-columns: 1fr 30%;
  }
`;

const Shelf = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  ${({ theme }) => theme.mediaQueries.mobile} {
    gap: 12px;
    ${FlatCard} {
      padding: 16px 12px;
      gap: 8px;
      align-items: center;
      text-align: center;
      & > svg {
        width: 36px;
        height: 36px;
      }
      ${Body1} {
        font-size: 11px;
        line-height: 16px;
      }
    }
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    grid-column: 1 / span 2;
    gap: 32px;

    ${FlatCard} {
      padding: 32px 44px;
      justify-content: center;
      flex-direction: row;
      text-align: left;
      & > svg {
        margin-left: -22px;
        width: 60px;
        height: 60px;
      }
    }
  }
`;
