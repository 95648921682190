import React from "react";
import styled from "styled-components";
import { Body1, H3 } from "melodies-source/Text";
import { ReactComponent as PulseIcon } from "assets/svg/pulse.svg";

interface Props {
  message: string;
}

export const InsufficientData: React.FC<Props> = ({ message }) => {
  return (
    <Container>
      <StyledPulseIcon />
      <H3>Insufficient Data</H3>
      <Body1>{message}</Body1>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 20px;
  background: #f3f6f9;
  width: 100%;
  color: #999;
  padding: 40px;
`;

const StyledPulseIcon = styled(PulseIcon)`
  color: #d1adff;
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
`;
