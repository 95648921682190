import React, { useEffect, useState } from "react";
import { PieChart } from "../Components/PieChart";
import { KeyedValue } from "Components";
import {
  PresaveSource,
  presaveSources,
  ReleasePresave,
} from "@max/common/dist/artists/presave";

interface Props {
  preSaves: ReleasePresave[];
  releaseId?: string;
}

export const SourceChart: React.FC<Props> = ({ releaseId, preSaves }) => {
  const [data, setData] = useState<KeyedValue[]>([]);

  const getData = () => {
    const sourceLabels: Record<PresaveSource, string> = {
      "setbio:takeover": "SET.Bio Take-Over",
      "setbio:section": "SET.Bio Section",
      setfan: "SET.Fan",
      setlive: "SET.Live",
      auto: "Auto Pre-Save",
    };

    const preSavesBySource: Record<PresaveSource, number> = {
      "setbio:takeover": 0,
      "setbio:section": 0,
      setfan: 0,
      setlive: 0,
      auto: 0,
    };

    for (const preSave of preSaves) {
      for (const source of presaveSources) {
        if (source === preSave.completedReleases[releaseId].source) {
          preSavesBySource[source] += 1;
        }
      }
    }

    return Object.entries(preSavesBySource).map(([source, count]) => ({
      id: source,
      label: sourceLabels[source],
      count: count,
    }));
  };

  useEffect(() => {
    const data = getData();
    setData(data);
  }, [preSaves.length]);

  return <PieChart title="Pre-Save Source" data={data} />;
};
