import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Divider } from "Components";
import { ReactComponent as AddToLibraryIcon } from "assets/svg/add-to-library.svg";
import {
  ArtistRelease,
  ReleasePresave,
} from "@max/common/dist/artists/presave";

interface Props {
  preSaves: ReleasePresave[];
  releaseId?: string;
  release?: ArtistRelease;
}

export const TotalPreSaves: React.FC<Props> = ({
  releaseId,
  release,
  preSaves,
}) => {
  const [optinsCount, setOptinsCount] = useState(0);

  useEffect(() => {
    if (release?.saveFutureReleases) {
      setOptinsCount(
        preSaves.filter((preSave) => preSave.allReleasesSource === releaseId)
          .length,
      );
    }
  }, [preSaves.length]);

  return (
    <Container>
      {!release?.saveFutureReleases && <StyledAddToLibraryIcon />}
      <Total>
        <Count>{preSaves.length}</Count>
        <Description>Total Pre-Saves</Description>
      </Total>
      {release?.saveFutureReleases && (
        <>
          <Divider />
          <OptIns>
            <b>{optinsCount}</b> Fans opted-in to future pre-saves
          </OptIns>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 30px;
  text-align: center;
  border-radius: 20px;
  background: #f3f6f9;
  min-width: 250px;
`;

const Total = styled.div`
  text-align: center;
`;

const Count = styled.span`
  font-size: 38px;
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: 500;
`;

const OptIns = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  max-width: 200px;
`;

const StyledAddToLibraryIcon = styled(AddToLibraryIcon)`
  color: #8017f6;
  margin-top: -15px;

  ${({ theme }) => theme.media.mobile} {
    width: 50px;
    height: 50px;
    margin-top: -5px;
  }
`;
