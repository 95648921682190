import React from "react";
import { PieChart as CustomPieChart, ListItem } from "Components/PieChart";
import { useIsMobile } from "melodies-source/utils";
import styled, { useTheme } from "styled-components";
import { H3 } from "melodies-source/Text";
import { KeyedValue } from "Components";

interface Props {
  title: string;
  data: KeyedValue[];
}

export const PieChart: React.FC<Props> = ({ title, data }) => {
  const theme = useTheme();

  return (
    <Container>
      <H3>{title}</H3>
      <StyledPieChart
        dataKey="count"
        nameKey="label"
        data={data}
        layout="vertical"
        colors={theme.custom?.charts?.donut.colors}
      />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const StyledPieChart = styled(CustomPieChart)`
  max-width: 250px;

  ${ListItem} {
    display: grid;
    grid-template-columns: 20px 1fr auto;
    text-align: left;
  }

  ${({ theme }) => theme.media.mobile} {
    max-width: none;
  }
`;
