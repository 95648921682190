import React, { useEffect, useState } from "react";
import { PieChart } from "../Components/PieChart";
import { KeyedValue } from "Components";
import { ReleasePresave } from "@max/common/dist/artists/presave";

const SERVICES = ["spotify", "apple"] as const;
type Service = (typeof SERVICES)[number];

interface Props {
  preSaves: ReleasePresave[];
}

export const ServiceChart: React.FC<Props> = ({ preSaves }) => {
  const [data, setData] = useState<KeyedValue[]>([]);

  const getData = () => {
    const serviceLabels: Record<Service, string> = {
      spotify: "Spotify",
      apple: "Apple Music",
    };

    const preSavesByService: Record<Service, number> = {
      spotify: 0,
      apple: 0,
    };

    for (const preSave of preSaves) {
      const service = preSave.service;
      if (SERVICES.includes(service)) {
        preSavesByService[service] += 1;
      }
    }

    return Object.entries(preSavesByService).map(([service, count]) => ({
      id: service,
      label: serviceLabels[service],
      count,
    }));
  };

  useEffect(() => {
    const data = getData();
    setData(data);
  }, [preSaves.length]);

  return <PieChart title="Pre-Save Service" data={data} />;
};
